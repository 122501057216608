<template >
    <div  v-if="dsLeadBid.state.isLoaded && dsLeadBid.data.length === 0">
        <button class="btn btn-sm btn-outline-primary" @click="createBid"> 
             <span class="spinner-border spinner-border-sm d-none" aria-hidden="true" ref="spinner"></span>
            {{ $t("Create Bid") }}</button>            
    </div>
    <div v-else-if="dsLeadBid.state.isLoaded && dsLeadBid.data.length > 0" class="mt-n2">
        {{ $t("Bid")}}: <a :href="`/contract-procurement?ID=${dsLeadBid.current.ID}`">{{ dsLeadBid.current.ID}} - {{ dsLeadBid.current.Procurement}}</a>
    </div>
</template>

<script setup>
    import { OPersonsLookup, OOrgUnitsLookup } from 'o365-system-lookups';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure } from 'o365-modules';
    import { defineProps,ref } from 'vue';
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    });

    const procCreateBid = getOrCreateProcedure({ id:"procCreateBid", procedureName:"astp_Contract_NewProcurement" });
    const spinner = ref(null)
    

    const dsLeadBid = getOrCreateDataObject(
    {
        "id": "dsLeadBid",
        "viewName": "aviw_Contract_Procurements",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID"},
            {"name": "Procurement"}
        ],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null
    });


    const dsLeadScopeItem = getOrCreateDataObject(
    {
        "id": "dsLeadScopeItem",
        "viewName": "atbv_Scope_Items",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "OrgUnit_ID"},
            {"name": "Title"},
        ],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null
    });


    dsLeadBid.recordSource.whereClause = "ScopeItem_ID = " + props.itemId;
    dsLeadBid.load();

    dsLeadScopeItem.recordSource.whereClause = "ID = " + props.itemId;
    dsLeadScopeItem.load();
    

    function createBid(){
        spinner.value.classList.remove("d-none");
        procCreateBid.execute({ScopeItem_ID: props.itemId, OrgUnit_ID: dsLeadScopeItem.current.OrgUnit_ID, Name:dsLeadScopeItem.current.Title }).then((pData)=>{
            dsLeadBid.recordSource.whereClause = "ID = " + pData.Table[0].ID;
            dsLeadBid.load();
        }).catch((pErr)=>{
            
        }).finaly(()=>{
            spinner.value.classList.add("d-none");
        });
    }


</script>